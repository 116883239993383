


/* logo */

.isekaisoft-logo {
    box-sizing: border-box;
    border-style: solid;
    height: auto;
    max-width: 89%;
    border: none;
    border-radius: 8px;
    box-shadow: rgb(0, 0, 0) 0px 0px 8px 0.5px;
    opacity: 1;
    filter: brightness(133%) contrast(200%) saturate(200%) blur(0px) hue-rotate(0deg);
    border-width: 5px;
    border-color: rgba(0, 0, 0, 0);
    background-color: rgba(255, 255, 255, 0.45);
    vertical-align: middle;
    display: inline-block;
    transition-duration: 0.3s;
    transition-property: transform
  }